.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  align-items: center;
}

.settingsContainer {
  margin-top: 110px;
  margin-bottom: 40px;
  width: 80%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.userProfileContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userProfileContentContainer {
  border-radius: 16px;
  background-color: var(--card-color);
}

.profilePictureContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}

.usernameContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.usernameLeftContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usernameRightContainer {
  display: flex;
}

.accountAndPrivacyContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accountAndPrivacyCountentContainer {
  border-radius: 16px;
  background-color: var(--card-color);
}

.deleteAccountContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.deleteAccountLeftContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.deleteAcountRightContainer {
  display: flex;
  align-items: center;
}

.titleText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--main-black-color);
}

.valueText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--main-black-color);
}

.editButton {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  cursor: pointer;
}
