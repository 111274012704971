.container {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.selectionContainer {
  display: flex;
  margin-right: 24px;
}

.selectionImage {
  width: 32px;
  height: 32px;
}

.cardContainer {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--card-color);
  border-radius: 8px;
  cursor: pointer;
}

.topContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isCorrectAnswerForLearningResultContainer {
  display: flex;
}

.moreButtonContainer {
  display: flex;
}

.moreButtonImage {
  width: 32px;
  height: 32px;
}

.termContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  gap: 12px;
}

.termText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--main-black-color);
}

.definitionText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
}

.memoText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-gray-color);
}

.pronunciationText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.groupText {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}
