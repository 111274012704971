.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.levelText {
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--white-color);
}

.levelOneStyle {
  background-color: var(--level-one-color);
}

.levelTwoStyle {
  background-color: var(--level-two-color);
}

.levelThreeStyle {
  background-color: var(--level-three-color);
}
