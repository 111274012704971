.container {
  width: 100%;
  height: 40px;
  display: flex;
  background-color: var(--button-color);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.searchImage {
  margin: 0;
  margin-left: 8px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.searchInput {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: var(--main-black-color);
  caret-color: var(--main-black-color);
  border: none;
  outline: none;
  background: none;
}
