.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
}

.pageText {
  margin: 0;
  margin-left: 4px;
  font-size: 16px;
  font-weight: 700;
  color: var(--light-gray-color);
}

.removeButtonContainer {
  display: flex;
  cursor: pointer;
}

.fieldTitle {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-gray-color);
}
