.stack {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: var(--background-transparent-color);
}

.optionsContainer {
  width: 70%;
  max-height: 100%;
  min-width: 320px;
  background-color: var(--white-color);
  border-radius: 16px;
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
}

.contentsContainer {
  width: 100%;
  height: 100%;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.learningTitleText {
  padding: 0;
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  color: var(--title-black-color);
}

.settingsTitleText {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--main-black-color);
}

.settingsValueText {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  color: var(--light-gray-color);
}

.dropDownImage {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
}

.learningGroupSettingsContainer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupSettingsValueContainer {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.learningLevelSettingsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.levelSettingsValueContainer {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.learningTermsCountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.learningTermsCountInputContainer {
  width: 100px;
  display: flex;
}

.learningSortSettingsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.learningTypeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.learningPronunciationPositionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.learningIntervalUnitCountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.learningTextToSpeechContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multipleChoiceContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.learningWrongAnswerNoteContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsContainer {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.inLearningButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.restartButton {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--delete-color);
  cursor: pointer;
}

.inLearningButtonsRightContainer {
  display: flex;
  gap: 16px;
}
