.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
}

.contentContainer {
  width: 400px;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slotPurchaseTitleText {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-color);
}

.slotPriceText {
  margin: 0;
  margin-top: 24px;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  color: var(--main-black-color);
}

.slotPurchaseSubDescriptionText {
  white-space: pre-wrap;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 40px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--dark-gray-color);
}

.desciptionText {
  white-space: pre-wrap;
  margin: 0;
  margin-top: 40px;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--main-black-color);
}

.highlightText {
  color: var(--primary-color);
  cursor: pointer;
}
