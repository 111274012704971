.container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.isCorrectAnswerForLearningResultContainer {
  display: flex;
}

.cardContainer {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--card-color);
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.02),
    0 -1px 3px rgba(0, 0, 0, 0.015), 0 1px 2px rgba(0, 0, 0, 0.01);
  transition: box-shadow 0.3s ease-in-out;
}

.topContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topLeftContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.moreButtonContainer {
  display: flex;
}

.moreButtonImage {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.updatedLevelText {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.termContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  gap: 12px;
}

.termText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--main-black-color);
}

.definitionText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
}

.memoText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-gray-color);
}

.pronunciationText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.groupContainer {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.groupText {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}
