.bigButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
}

.title {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
}

.styleNormal {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
}

.styleDisable {
  background-color: var(--button-color);
  color: var(--light-gray-color);
  border: none;
}

.styleSpecial {
  background-color: var(--white-color);
  color: var(--primary-color);
  border-color: var(--primary-color);
  border-width: 2px;
}

.styleClear {
  background-color: transparent;
  color: var(--main-black-color);
  border-color: var(--disabled-color);
  border-width: 1px;
  border-style: solid;
}
