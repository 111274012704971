.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placeHolderTextField {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  background-color: var(--button-color);
}

.input {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--main-black-color);
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  caret-color: var(--main-black-color);
}

.textarea {
  width: 100%;
  height: auto;
  min-height: 10px;
  max-height: 95px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--main-black-color);
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  caret-color: var(--main-black-color);
  overflow-y: auto;
  resize: none;
  box-sizing: border-box;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
}

.input:focus {
  border: none;
  outline: none;
}

.maxCountText {
  margin: 0px;
  margin-right: 16px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.maxCountNormal {
  color: var(--light-gray-color);
}

.maxCountOver {
  color: red;
}

.errorMessageText {
  margin: 0;
  margin-left: 4px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  color: red;
}
