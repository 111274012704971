.stack {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-transparent-color);
  z-index: 50;
}

.termEditContainer {
  width: 50%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-color);
  border-radius: 16px;
}

.groupContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.groupSelectValue {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-color);
  cursor: pointer;
}

.contentContainer {
  height: 100%;
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentTitleAndContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contentTitle {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 40px;
  margin-bottom: 24px;
  gap: 16px;
}
