.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.title {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 700;
}

.styleNormal {
  background-color: var(--primary-color);
  color: #ffffff;
}

.styleDisable {
  background-color: var(--button-color);
  color: var(--light-gray-color);
}

.styleSimple {
  background-color: var(--card-color);
  color: var(--main-black-color);
}
