.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  background-color: var(--card-color);
  border-radius: 16px;
  cursor: pointer;
}

.typeIcon {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  padding: 0;
  width: 60px;
  height: 60px;
}

.titleText {
  margin: 0;
  margin-left: 40px;
  margin-top: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  color: var(--main-black-color);
}

.messageText {
  margin: 0;
  margin-left: 40px;
  margin-top: 8px;
  margin-bottom: 40px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}
