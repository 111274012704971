.container {
  width: 152px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  border-radius: 8px;
}

.selectedContainer {
  background-color: var(--primary-color-opacity);
}

.hoverContainer {
  background-color: var(--card-color);
}

.icon {
  margin-left: 8px;
  width: 32px;
  height: 32px;
  color: var(--main-black-color);
}

.selectedIcon {
  color: var(--primary-color);
}

.titleText {
  margin: 0;
  padding: 0;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 700;
  color: var(--main-black-color);
}

.selectedTitleText {
  color: var(--primary-color);
}
