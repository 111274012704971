.container {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--white-color);
  z-index: 50;
}

.topNavigationScreen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftButtonsContainer {
  margin-left: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.leftBarToggleButton {
  color: var(--main-black-color);
  cursor: pointer;
}

.onevocaLogo {
  height: 32px;
  cursor: pointer;
}

.menuTabContainer {
  height: 100%;
  margin-left: 24px;
  display: flex;
  gap: 16px;
}

.space {
  width: 100%;
}

.userContainer {
  height: 100%;
  margin-left: 16px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}
