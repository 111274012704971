.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-transparent-color);
  z-index: 50;
}

.termAddContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 320px;
  height: 90%;
  background-color: var(--white-color);
  border-radius: 16px;
}

.groupSelectContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 24px;
  gap: 16px;
}

.groupSelectTitleText {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.termAddFieldsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  gap: 24px;
  overflow-y: auto;
}

.termAddFieldTabKeyTipText {
  white-space: pre-wrap;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.bottomButtonContainer {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  gap: 16px;
}
