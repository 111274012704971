.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: var(--background-transparent-color);
  z-index: 50;
}

.dialogContainer {
  width: 50%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border-radius: 16px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  margin: 40px;
}

.titleAndMessageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.titleText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--main-black-color);
}

.messageText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: (var(--main-black-color));
}

.textFieldContainer {
  margin-top: 24px;
  display: flex;
}

.buttonsContainer {
  margin-top: 40px;
  display: flex;
  gap: 16px;
  align-self: flex-end;
}
