.stack {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
}

.stack > * {
  grid-area: stack;
}

.loginScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
  border-radius: 4px;
}

.loginButtonContainer {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.termsOfUseContainer {
  height: 100vh;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 8px;
}

.termsOfUseSpacer {
  margin: 0px;
  padding: 0px;
  color: var(--disabled-color);
}

.onevocaLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signUpContainer {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
