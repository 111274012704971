.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}

.styleClear {
  background-color: transparent;
  color: var(--main-black-color);
  border-color: var(--disabled-color);
  border-width: 1px;
  border-style: solid;
}

.stylePrimary {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
}

.styleDelete {
  background-color: var(--delete-color);
  color: #ffffff;
  border: none;
}
