.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: var(--background-transparent-color);
}

.checkBoxContainer {
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
  display: flex;
}
