.mainStack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-template-areas: "stack";
}

.mainStack > * {
  grid-area: stack;
}

.mainScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

.container {
  display: flex;
  flex-direction: row;
}

.contentsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-color);
}

.sectionContainer {
  margin-top: 70px;
  height: 100%;
}
