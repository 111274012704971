.container {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 50;
  position: fixed;
}

.userInfoContainer {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
}

.userInfoTextContainer {
  margin-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.slotContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.mySlotCountText {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--main-black-color);
}

.mySlotCountTextHighlight {
  color: var(--primary-color);
}

.usernameText {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  color: var(--main-black-color);
}

.userEmailText {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--main-black-color);
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.button {
  border: none;
  background: none;
  margin: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: start;
  color: var(--dark-gray-color);
  cursor: pointer;
}

.featursContainer {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

.logOutContainer {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

.otherContainer {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}
