.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentText {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--main-black-color);
  white-space: pre-line;
  line-height: 24px;
}
