.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--card-color);
}

.contentContainer {
  width: 100%;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1200px) {
  .contentContainer {
    width: 80%;
  }
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.titleText {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 900;
  white-space: pre-wrap;
  line-height: 32px;
  color: var(--main-black-color);
}

.titleIcon {
  margin: 0;
  padding: 0;
  font-size: 60px;
}

.progressContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  gap: 24px;
}

.progressCorrectOrIncorrectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progressCorrectTermsCountContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border-radius: 16px;
  background-color: var(--level-three-color-opacity-1a);
}

.progressIncorrectTermsCountContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border-radius: 16px;
  background-color: var(--level-two-color-opacity-1a);
}

.progressCorrectTermsCountText {
  margin: 0;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0;
  font-size: 14px;
  font-weight: 900;
  color: var(--level-three-color);
}

.progressIncorrectTermsCountText {
  margin: 0;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0;
  font-size: 14px;
  font-weight: 900;
  color: var(--level-two-color);
}

.buttonsContainer {
  width: 100%;
  margin: 80px;
  display: flex;
  gap: 24px;
}

.termsContainer {
  margin-top: 40px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
