.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: var(--background-transparent-color);
}

.container {
  position: fixed; /* Ensure it's fixed relative to the viewport */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: var(--background-transparent-color);
}

.groupSelectContainer {
  width: 50%;
  height: 85%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-color);
  border-radius: 16px;
}

.searchContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.groupAddContainer {
  margin-left: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 20px;
  cursor: pointer;
}

.groupEditContainer {
  margin-left: 16px;
  min-width: 32px;
  max-width: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--only-white-color);
  border-radius: 20px;
  cursor: pointer;
}

.listContainer {
  height: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
}

.emptyListTextContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  margin: 24px;
  display: flex;
  gap: 24px;
}
