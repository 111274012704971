.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  align-items: center;
}

.contentContainer {
  width: 80%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.companyNameText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--main-black-color);
}

.companyRegistrationNumberText {
  margin: 0;
  padding: 0;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.companyAddressText {
  margin: 0;
  padding: 0;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.contactAddressText {
  margin: 0;
  padding: 0;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
  cursor: pointer;
}

.privacyPolicyContainer {
  margin-top: 40px;
  display: flex;
  gap: 24px;
}

.buttonText {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 500px;
  color: var(--dark-gray-color);
  cursor: pointer;
}

.socialLinkButtonContainer {
  margin-top: 40px;
  display: flex;
  gap: 16px;
}
