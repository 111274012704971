.containerForMotion {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 -1px 3px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  perspective: 1000px;
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: var(--card-color);
  }
}

.topContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  display: flex;
  cursor: pointer;
}

.contentsContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pronunciationText {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  font-size: 18px;
  color: var(--light-gray-color);
  user-select: none;
}

.questionText {
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: var(--main-black-color);
  user-select: none;
}

.memoText {
  margin: 0;
  margin-top: 24px;
  padding: 0;
  font-size: 18px;
  color: var(--dark-gray-color);
  user-select: none;
}

.answerContainer {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.answerTitleText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-gray-color);
}

.answerTitleTextCorrect {
  color: var(--level-three-color);
  font-weight: 800;
}

.answerTitleTextIncorrect {
  color: var(--level-two-color);
  font-weight: 800;
}

.answerButtonsContainer {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.answerButtonsFirstLine {
  width: 100%;
  display: flex;
  gap: 24px;
}

.answerButtonsSecondLine {
  width: 100%;
  display: flex;
  gap: 24px;
}

.passButtonContainer {
  margin-top: 24px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.passButton {
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
}

.passButtonDisable {
  color: var(--disabled-color);
}

.continueButton {
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  background: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--primary-color-opacity);
}
