.stack {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  justify-content: space-between;
}

.container {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.termsOfUseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.termsOfUseSpacer {
  margin: 0px;
  padding: 0px;
  color: var(--disabled-color);
}
