.container {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.languageText {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--main-black-color);
}
