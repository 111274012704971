.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: var(--background-transparent-color);
  z-index: 50;
}

.pickerContainer {
  width: 50%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 16px;
}

.displayText {
  margin: 0;
  padding: 0;
  margin-top: 60px;
  font-size: 20px;
  font-weight: 700;
}

.stepperButtonsContainer {
  margin-top: 32px;
  display: flex;
  gap: 2px;
}

.stepperButton {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.buttonsContainer {
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 60px;
  margin-bottom: 24px;
  display: flex;
  gap: 16px;
}
