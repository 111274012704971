.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--card-color);
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: var(--white-color);
  }
}

.topContainer {
  width: 100%;
  height: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white-color);
}

.learningTypeContainer {
  flex: 1;
  height: 100%;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.learningTypeText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--title-black-color);
}

.groupsContainer {
  flex: 1;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupsText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--main-black-color);
}

.buttonsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 24px;
  gap: 8px;
}

.learningContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 40px;
  overflow: visible;
}

@media (min-width: 1200px) {
  .learningContainer {
    width: 80%;
  }
}

.progressContainer {
  margin-top: 40px;
  width: calc(100% - 48px);
  min-width: 320px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.progress {
  flex-grow: 1;
  height: 12px;
  border-radius: 6px;
}
