.container {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
}

.topContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.moreButtonContainer {
  display: flex;
}

.moreButtonImage {
  width: 32px;
  height: 32px;
}

.moreButtonEmptyContainer {
  width: 32px;
  height: 32px;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
}

.selectedContainer {
  background-color: var(--primary-color);
}

.readyContainer {
  background-color: var(--card-color);
}

.leftContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.groupnameText {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.selectedGroupnameText {
  color: #ffffff;
}

.readyGroupnameText {
  color: var(--main-black-color);
}

.termCountText {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.selectedTermCountText {
  color: #ffffff;
}

.readyTermCountText {
  color: var(--light-gray-color);
}

.rightContainer {
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learningPercentangeText {
  font-size: 14px;
  font-weight: 500;
}

.selectedPercentageText {
  color: #ffffff;
}

.readyPercentageText {
  color: var(--light-gray-color);
}
