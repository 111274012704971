.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 80%;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

.topContainer {
  margin-top: 40px;
  width: 100%;
  height: 56px;
  display: flex;
}

.groupSelectedContainer {
  display: flex;
  cursor: pointer;
}

.groupSelectedTitle {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  color: var(--main-black-color);
}

.groupSelectDropDownImage {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

.listSettingContainer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropDownMenuContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.listFeatursContainer {
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.selectionModeButtonsContainer {
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.searchContainer {
  margin-top: 40px;
  display: flex;
}

.termAddButtonContainer {
  margin-left: 16px;
  cursor: pointer;
}

.termsContainer {
  margin-top: 16px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.termsEmptyTextContainer {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.scrollToTopButtonContainer {
  position: fixed;
  right: 10%;
  bottom: 0px;
}

.scrollToTopButton {
  color: var(--main-black-color);
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
  width: 24px;
  height: 24px;
  background-color: var(--white-color);
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* styles.css */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
}
