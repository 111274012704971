.container {
  width: 100%;
  display: flex;
  background-color: var(--white-color);
  border-radius: 8px;
  border-width: 2px;
  border-color: var(--button-color);
  border-style: solid;
  cursor: pointer;
}

.containerCorrect {
  border-color: var(--level-three-color);
  border-style: dotted;
}

.containerIncorrect {
  border-color: var(--level-two-color);
  border-style: solid;
}

.value {
  margin: 0;
  padding: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-gray-color);
  user-select: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}
