.stack {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  justify-content: space-between;
}

.container {
  width: 320px;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.forgotPasswordContainer {
  display: flex;
  margin-top: 24px;
  justify-content: end;
}

.loginButtonContainer {
  display: flex;
  margin-top: 56px;
}

.signUpButtonContainer {
  margin-bottom: 60px;
}
