.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.container {
  position: fixed; /* Ensure it's fixed relative to the viewport */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: var(--background-transparent-color);
}

.languageSelectContainer {
  width: 320px;
  height: 75%;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  border-radius: 16px;
}

.searchContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  display: flex;
}

.headerText {
  margin: 0;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-gray-color);
}

.listContainer {
  height: 100%;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
