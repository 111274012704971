.containerForMotion {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05),
    /* 하단의 주요 그림자 */ 0 3px 6px rgba(0, 0, 0, 0.04),
    /* 하단의 부드러운 그림자 */ 0 -1px 3px rgba(0, 0, 0, 0.03),
    /* 상단으로 약간 퍼지는 그림자 */ 0 1px 2px rgba(0, 0, 0, 0.02); /* 얕은 상단 그림자 */
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  perspective: 1000px;
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: var(--card-color);
  }
}

.container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07),
    /* 하단 그림자 확대 */ 0 4px 8px rgba(0, 0, 0, 0.05),
    /* 하단 그림자 추가 */ 0 -2px 4px rgba(0, 0, 0, 0.04),
    /* 상단 그림자 약간 확대 */ 0 2px 4px rgba(0, 0, 0, 0.03); /* 얕은 상단 그림자 */
}

.flipped {
  transform: rotateX(180deg); /* 뒤집힌 상태에서 회전 추가 */
}

.topContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  display: flex;
  cursor: pointer;
}

.contentsContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
}

.flipped {
  transform: rotateX(180deg);
}

.questionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.answerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pronunciationText {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  color: var(--light-gray-color);
  user-select: none;
}

.questionText {
  margin: 0;
  padding: 0;
  font-size: 32px;
  text-align: center;
  color: var(--main-black-color);
  user-select: none;
}

.memoText {
  margin: 0;
  margin-top: 24px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  color: var(--dark-gray-color);
  user-select: none;
}

.buttonsContainer {
  margin-bottom: 40px;
  width: calc(100% - 64px);
  display: flex;
  gap: 24px;
}
