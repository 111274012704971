.containerForMotion {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.04),
    0 -1px 3px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  perspective: 1000px;
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: var(--card-color);
  }
}

.topContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  display: flex;
  cursor: pointer;
}

.contentsContainer {
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pronunciationText {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  font-size: 18px;
  color: var(--light-gray-color);
  user-select: none;
}

.questionText {
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: var(--main-black-color);
  user-select: none;
}

.memoText {
  margin: 0;
  margin-top: 24px;
  padding: 0;
  font-size: 18px;
  color: var(--dark-gray-color);
  user-select: none;
}

.answerContainer {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.yourAnswerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.yourAnswerTitle {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-gray-color);
}

.yourAnswerTitleCorrect {
  color: var(--level-three-color);
  font-weight: 800;
}

.yourAnswerTitleIncorrect {
  color: var(--level-two-color);
  font-weight: 800;
}

.answerResultContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.wrongAnswerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.correctAnswerText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--level-three-color);
}

.answerCorrectContainer {
  border: none;
  background: none;
  width: 100%;
  height: 56px;
  border-width: 2px;
  border-style: dotted;
  border-color: var(--level-three-color);
  text-align: left;
  padding-left: 16px;
  font-size: 16px;
  color: var(--main-black-color);
}

.incorrectAnswerText {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  color: var(--level-two-color);
}

.answerIncorrectContainer {
  border: none;
  background: none;
  width: 100%;
  height: 56px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--level-two-color);
  text-align: left;
  padding-left: 16px;
  font-size: 16px;
  color: var(--main-black-color);
}
