.stack {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}

.container {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 56px;
}
