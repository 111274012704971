.textButton {
  display: inline-flex;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background: none;
  height: 16.8px;
  cursor: pointer;
}

.buttonTitle {
  margin: 0;
}

.buttonTitleUnderline {
  text-decoration: underline;
}
