.loginButton {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  cursor: pointer;
}

.buttonImage {
  margin-left: 8px;
  width: 24px;
  height: 24px;
}

.buttonTitle {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}

.borderNone {
  border: none;
}

.borderSolid {
  border-width: 1px;
  border-style: solid;
  border-color: var(--disalbed-color);
}
