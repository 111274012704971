body {
  background-color: var(--white-color);
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --only-white-color: #ffffff;
  --primary-color: #6360f6;
  --primary-color-opacity: #6360f61a;
  --title-black-color: #1a1a1a;
  --main-black-color: #2b2b2b;
  --dark-gray-color: #616161;
  --light-gray-color: #909090;
  --sub-light-gray-color: #dadada;
  --sub-light-gray-color-opacity: #dadada80;
  --white-color: #ffffff;
  --button-color: #f6f6f6;
  --card-color: #f6f6f6;
  --disabled-color: #b0b0ba;
  --level-one-color: #fa6d6d;
  --level-two-color: #fab36d;
  --level-two-color-opacity: #fab36d80;
  --level-two-color-opacity-1a: #fab36d1a;
  --level-three-color: #62e1ac;
  --level-three-color-opacity: #62e1ac80;
  --level-three-color-opacity-1a: #62e1ac1a;
  --background-transparent-color: #00000015;
  --delete-color: #e53935;
}

@media (prefers-color-scheme: dark) {
  :root {
    --only-white-color: #ffffff;
    --primary-color: #6360f6;
    --primary-color-opacity: #6360f61a;
    --title-black-color: #ffffff;
    --main-black-color: #ffffff;
    --dark-gray-color: #dadada;
    --light-gray-color: #909090;
    --sub-light-gray-color: #616161;
    --sub-light-gray-color-opacity: #61616180;
    --white-color: #121212;
    --button-color: #2b2b2b;
    --card-color: #2b2b2b;
    --disabled-color: #b0b0ba;
    --level-one-color: #fa6d6d;
    --level-two-color: #fab36d;
    --level-two-color-opacity: #fab36d80;
    --level-two-color-opacity-1a: #fab36d1a;
    --level-three-color: #62e1ac;
    --level-three-color-opacity: #62e1ac80;
    --level-three-color-opacity-1a: #62e1ac1a;
    --background-transparent-color: #30303099;
    --delete-color: #e53935;
  }
}
