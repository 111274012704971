.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: var(--background-transparent-color);
  z-index: 100;
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  left: 0px;
  background-color: var(--white-color);
}

.leftButtonsContainer {
  margin-left: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.leftBarToggleButton {
  color: var(--main-black-color);
  cursor: pointer;
}

.onevocaLogo {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topNavContainer {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabBarButtons {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
