.stack {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  justify-items: center;
  grid-template-areas: "stack";
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 80%;
  min-width: 320px;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
}

.learningItemContainer {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
