.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: var(--card-color);
  border-radius: 4px;
}

.valueText {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: var(--main-black-color);
}

.rightArrowImage {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  transform: rotate(90deg);
}
