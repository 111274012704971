.stack {
  width: 100vw;
  height: 100vh;
  display: grid;
  position: relative;
}

.stack > * {
  grid-area: stack;
}

.screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
}

.container {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentTitle {
  margin: 0;
  color: var(--main-black-color);
  font-size: 20px;
  font-weight: bold;
}

.contentEmail {
  margin: 0;
  margin-top: 40px;
  color: var(--main-black-color);
  font-size: 16px;
  font-weight: bold;
}

.contentConfirmEmailMessage {
  margin: 0;
  margin-top: 6px;
  color: var(--main-black-color);
  font-size: 16px;
  font-weight: 500;
}

.buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 16px;
}
